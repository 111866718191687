import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  FormControl,
  Typography,
  Divider,
} from "@mui/material";
import UserAnalyticsChart from "./UserAnalyticsChart";
import axios from "axios";
import TrialDate from "../input/TrialDate";
import moment from "moment";
import configParam from "../Config";
import { useReactToPrint } from "react-to-print";
import Logo from "../asset/Logo.png";

const containerStyle = {
  textAlign: "center",
  padding: "0px",
};

const trialHeadingStyle = {
  fontWeight: 700,
  fontSize: "14px",
  color: "#000000",
  padding: "0px",
};

const textStyle = {
  color: "#616161",
  fontSize: "14px",
  fontFamily: "DM Sans, sans-serif",
};

const textValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
};

const totalTextValueStyle = {
  fontWeight: 800,
  fontSize: "18px",
  fontFamily: "DM Sans, sans-serif",
  color: "#0086DC",
};

const NewUserAnalytics = (props) => {
  const ApiURL = configParam.API_URL;
  const [statusCount, setStatusCount] = useState([]);
  const [error, setError] = useState(null);
  const [rmcData, setRmcData] = useState({
    TotalTrials: 0,
    Success: 0,
    Fail: 0,
  });
  const [jobTypeData, setJobTypeData] = useState({
    TotalTrials: 0,
    Success: 0,
    Fail: 0,
  });
  const [isPrint, setIsPrint] = useState(false);
  const componentRef = useRef();

  // Initialize default date range: From Jan 1, 2022 to the current date
  const defaultFromDate = moment("2022-01-01").format("YYYY-MM-DD");
  const defaultToDate = moment().format("YYYY-MM-DD");

  const [fromTrialDate, setFromTrialDate] = useState(defaultFromDate);
  const [toTrialDate, setToTrialDate] = useState(defaultToDate);

  const fetchUserAndCardData = async () => {
    try {
      const response = await axios.get(
        `${ApiURL}get_trial_info_project_type/`,
        {
          params: {
            fromDate: fromTrialDate,
            toDate: toTrialDate,
            user_id: props.data.id,
          },
        }
      );

      if (response.data.length > 0) {
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Initialize totals for RMX and Jobsite based on provided data
        let totalRmxTrials = 0;
        let totalJobsiteTrials = 0;
        let totalRmxSuccess = 0;
        let totalRmxFail = 0;
        let totalJobsiteSuccess = 0;
        let totalJobsiteFail = 0;

        // Prepare month-wise status counts
        const monthlyStatusCounts = monthNames.map((month, index) => {
          const monthData = response.data.filter(
            (item) => moment(item.period).month() === index
          );

          // Calculate month-wise status counts
          const total = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.trials_count, 10) || 0),
            0
          );
          const approved = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.approved_count, 10) || 0),
            0
          );
          const resubmitted = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.resubmit_count, 10) || 0),
            0
          );
          const rejected = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.rejected_count, 10) || 0),
            0
          );
          const pending = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.pending_count, 10) || 0),
            0
          );
          const success = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.success_count, 10) || 0),
            0
          );
          const fail = monthData.reduce(
            (acc, curr) => acc + (parseInt(curr.fail_count, 10) || 0),
            0
          );

          return {
            month,
            Total: total,
            Approved: approved,
            Resubmitted: resubmitted,
            Rejected: rejected,
            Pending: pending,
            Success: success,
            Fail: fail,
          };
        });

        // Calculate overall RMX and Jobsite totals from the provided data
        response.data.forEach((item) => {
          totalRmxTrials += parseInt(item.rmx_trials_count, 10) || 0;
          totalRmxSuccess += parseInt(item.rmx_success_count, 10) || 0;
          totalRmxFail += parseInt(item.rmx_fail_count, 10) || 0;

          totalJobsiteTrials += parseInt(item.jobsite_trials_count, 10) || 0;
          totalJobsiteSuccess += parseInt(item.jobsite_success_count, 10) || 0;
          totalJobsiteFail += parseInt(item.jobsite_fail_count, 10) || 0;
        });

        // Set the state with monthly status counts and overall RMX/Jobsite totals
        setStatusCount(monthlyStatusCounts);
        setRmcData({
          TotalTrials: totalRmxTrials,
          Success: totalRmxSuccess,
          Fail: totalRmxFail,
        });
        setJobTypeData({
          TotalTrials: totalJobsiteTrials,
          Success: totalJobsiteSuccess,
          Fail: totalJobsiteFail,
        });
      } else {
        // Clear data if no response
        setStatusCount([]);
        setRmcData({ TotalTrials: 0, Success: 0, Fail: 0 });
        setJobTypeData({ TotalTrials: 0, Success: 0, Fail: 0 });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
    }
  };

  useEffect(() => {
    if (props.open && fromTrialDate && toTrialDate) {
      fetchUserAndCardData();
    }
  }, [fromTrialDate, toTrialDate, props.data.id, props.open]);

  // Reset the date values when the dialog is closed
  useEffect(() => {
    if (!props.open) {
      // Reset to default dates when the dialog is closed
      setFromTrialDate(defaultFromDate);
      setToTrialDate(defaultToDate);
    }
  }, [props.open]);

  // Handle date range changes
  const FromToDatePicker = () => {
    const handleFromDateChange = (newValue) => {
      const formattedDate = moment(newValue.$d).format("YYYY-MM-DD");
      setFromTrialDate(formattedDate);
    };

    const handleToDateChange = (newValue) => {
      const formattedDate = moment(newValue.$d).format("YYYY-MM-DD");
      setToTrialDate(formattedDate);
    };

    return (
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Grid container spacing={2} justifyContent="flex-end">
          {/* From Date Picker */}
          <Grid item xs={12} sm={6} md={5}>
            <FormControl fullWidth>
              <TrialDate
                label="From"
                value={fromTrialDate}
                isDate={true}
                onChange={handleFromDateChange}
                maxLimit={new Date()}
                disabled={false}
              />
            </FormControl>
          </Grid>

          {/* To Date Picker */}
          <Grid item xs={12} sm={6} md={5}>
            <FormControl fullWidth>
              <TrialDate
                label="To"
                value={toTrialDate}
                isDate={true}
                onChange={handleToDateChange}
                maxLimit={new Date()}
                disabled={false}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };
  const renderAnalyticsCards = () => (
    <Grid
      container
      spacing={2}
      sx={{
        // background: "#EFEFEF",
        borderRadius: "8px",
        // padding: "16px",
      }}
    >
      {/* Total Trials Card */}
      <Grid
        item
        xs={12}
        sm={6}
        md={2.4}
        lg={2.4} // Adjusting for 5 cards in a row
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            padding: 0.5,
            background: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Typography style={trialHeadingStyle}>Number of Trials</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>RMX</Typography>
              <Typography style={textValueStyle}>
                {rmcData.TotalTrials || 0}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>JOB SITE</Typography>
              <Typography style={textValueStyle}>
                {jobTypeData.TotalTrials || 0}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>TOTAL</Typography>
              <Typography style={totalTextValueStyle}>
                {rmcData.TotalTrials + jobTypeData.TotalTrials || 0}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Successful Trials Card */}
      <Grid
        item
        xs={12}
        sm={6}
        md={2.4}
        lg={2.4} // Adjusting for 5 cards in a row
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            padding: 0.5,
            background: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Typography style={trialHeadingStyle}>Successful Trials</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>RMX</Typography>
              <Typography style={textValueStyle}>
                {rmcData.Success || 0}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>JOB SITE</Typography>
              <Typography style={textValueStyle}>
                {jobTypeData.Success || 0}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>TOTAL</Typography>
              <Typography style={totalTextValueStyle}>
                {rmcData.Success + jobTypeData.Success || 0}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Failed Trials Card */}
      <Grid
        item
        xs={12}
        sm={6}
        md={2.4}
        lg={2.4} // Adjusting for 5 cards in a row
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            padding: 0.5,
            background: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Typography style={trialHeadingStyle}>Failed Trials</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>RMX</Typography>
              <Typography style={textValueStyle}>
                {rmcData.Fail || 0}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>JOB SITE</Typography>
              <Typography style={textValueStyle}>
                {jobTypeData.Fail || 0}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>TOTAL</Typography>
              <Typography style={totalTextValueStyle}>
                {rmcData.Fail + jobTypeData.Fail || 0}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Success Ratio Card */}
      <Grid
        item
        xs={12}
        sm={6}
        md={2.4}
        lg={2.4} // Adjusting for 5 cards in a row
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            padding: 0.5,
            background: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Typography style={trialHeadingStyle}>Success Ratio (%)</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>RMX</Typography>
              <Typography style={totalTextValueStyle}>
                {rmcData.Success > 0
                  ? ((rmcData.Success / rmcData.TotalTrials) * 100).toFixed(0) +
                    "%"
                  : "0%"}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>JOB SITE</Typography>
              <Typography style={totalTextValueStyle}>
                {jobTypeData.Success > 0
                  ? (
                      (jobTypeData.Success / jobTypeData.TotalTrials) *
                      100
                    ).toFixed(0) + "%"
                  : "0%"}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>TOTAL</Typography>
              <Typography style={totalTextValueStyle}>
                {(
                  ((rmcData.Success + jobTypeData.Success) /
                    (rmcData.TotalTrials + jobTypeData.TotalTrials)) *
                  100
                ).toFixed(0) || 0}
                %
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Trials Per Day */}
      <Grid
        item
        xs={12}
        sm={6}
        md={2.4}
        lg={2.4} // Adjusting for 5 cards in a row
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            padding: 0.5,
            background: "#f5f5f5",
            borderRadius: 1,
          }}
        >
          <Typography style={trialHeadingStyle}>Trials Per Day</Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>RMX</Typography>
              <Typography style={textValueStyle}>
                {(rmcData.TotalTrials / 22).toFixed(1)}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>JOB SITE</Typography>
              <Typography style={textValueStyle}>
                {(jobTypeData.TotalTrials / 22).toFixed(1)}
              </Typography>
            </Grid>
            <Grid item style={containerStyle}>
              <Typography style={textStyle}>TOTAL</Typography>
              <Typography style={totalTextValueStyle}>
                {((rmcData.TotalTrials + jobTypeData.TotalTrials) / 22).toFixed(
                  1
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );

  // Save as PDF using react-to-print
  const handlePrint = useReactToPrint({
    onBeforePrint: () => setIsPrint(true),
    onAfterPrint: () => setIsPrint(false),
    content: () => componentRef.current,
    documentTitle: "User Analytics Report",
  });
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={"xl"}
      onClose={props.handleClose}
      ref={componentRef}
    >
      <DialogTitle id="user-analytics"></DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: "10px", marginTop: "-10px" }}
        >
          {/* Logo on the left */}
          <Grid
            item
            xs={12} // Full width on extra small devices
            sm={4} // 4/12 (1/3) of the width on small devices
            md={2} // 2/12 of the width on medium and large devices
            container
            justifyContent="flex-start"
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "100%", // Adjust to 100% to maintain responsiveness
                // maxWidth: 115,  // Maximum width for larger screens
                padding: 10,
              }}
            />
          </Grid>

          {/* Text in the center */}
          <Grid
            item
            xs={12} // Full width on extra small devices
            sm={8} // 8/12 (2/3) of the width on small devices
            md={6} // 6/12 (1/2) of the width on medium and larger devices
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: "16px", md: "18px" }, // Adjust font size based on screen size
                textAlign: "center",
              }}
            >
              {props.data.first_name} {props.data.last_name}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" }, // Adjust font size for better readability on smaller screens
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              ZONE - PERFORMANCE ANALYTICS
            </Typography>
          </Grid>

          {/* Date filters on the right */}
          <Grid
            item
            xs={12} // Full width on extra small devices
            md={4} // 4/12 of the width on medium and larger devices
            container
            justifyContent={{ xs: "center", md: "flex-end" }} // Center on small screens, align right on larger
            alignItems="center"
          >
            <FromToDatePicker />
          </Grid>
        </Grid>
        {renderAnalyticsCards()}
        <Grid>
          <UserAnalyticsChart statusCount={statusCount} isPrint={isPrint} />
        </Grid>
      </DialogContent>
      {/* <DialogContent>
        {renderTopCards()}
        <UserAnalyticsChart statusComment={statusComment} statusCount={statusCount} />
      </DialogContent> */}

      <DialogActions>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FFC400",
            color: "#1A1B1F",
            marginRight: "16px",
          }}
          onClick={handlePrint}
        >
          Save PDF
        </Button>
        <Button variant="outlined" onClick={props.handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewUserAnalytics;
