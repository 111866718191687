import React from "react";
import Chart from "react-apexcharts";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Root = styled("div")({
  "@media print": {
    "& .printChart": {
      // width: "580px !important", // Ensure the chart uses the full width when printed
      display: "block !important",
      height: "auto !important", // Let the height adjust automatically
      overflow: "hidden", // Prevent any horizontal overflow in print
    },
    "& .apexcharts-canvas": {
      // width: "580px !important", // Force full width in print
      height: "auto !important", // Adjust height to fit container in print
      overflow: "hidden", // Prevent content from overflowing horizontally
    },
  },
});

const UserAnalyticsChart = ({ statusCount, isPrint }) => {
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  console.log(isPrint);
  const barChart = {
    series: [
      { name: "Total", data: statusCount?.map((item) => item.Total || 0) },
      { name: "Pending", data: statusCount?.map((item) => item.Pending || 0) },
      { name: "Rejected", data: statusCount?.map((item) => item.Rejected || 0) },
      { name: "Resubmitted", data: statusCount?.map((item) => item.Resubmitted || 0) },
      { name: "Approved", data: statusCount?.map((item) => item.Approved || 0) },
      { name: "Success", data: statusCount?.map((item) => item.Success || 0) },
      { name: "Fail", data: statusCount?.map((item) => item.Fail || 0) },
    ],
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
      },
    },
    xaxis: {
      categories: monthNames,
    },
    colors: ["#009BFF", "#F7941D", "#E4003A", "#FEC401", "#229C5B", "#1B5B38", "#EA4335"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [
        "Total Trials", "Pending Trials", "Rejected Trials", "Resubmitted Trials",
        "Approved Trials", "Successful Trials", "Failed Trials"
      ],
      markers: {
        shape: "circle",
        fillColors: ["#009BFF", "#F7941D", "#E4003A", "#FEC401", "#229C5B", "#1B5B38", "#EA4335"],
      },
    },
  };

  return (
    <Root>
      <Box className="printChart">
        <Chart options={barChart} series={barChart.series} type="bar" width={isPrint ? "50%" : "70%"} height="350px" />
        <Typography variant="h6" sx={{ textAlign: "center", fontSize: "14px" }}>
          User Analytics
        </Typography>
      </Box>
    </Root>
  );
};

export default UserAnalyticsChart;
